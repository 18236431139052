
import { useTranslation } from 'react-i18next';
import { Link, Button, Chip, Code, Divider, Card, CardHeader, CardBody, CardFooter, Image, Badge, Avatar } from "@nextui-org/react";
import {useNavigate} from "react-router";

export const Header = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
            <a href="https://github.com/APXc" className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700" role="alert">
              <Chip className="px-4 py-1.5 mr-3" color="primary" variant="shadow" size='lg'>Created by</Chip>  
                {/* <span className="text-xs bg-primary-600 rounded-full text-white px-4 py-1.5 mr-3">Created by</span>  */}
                <span className=" text-sm  font-medium">Alessio Pellizzaro</span> 
            </a>
            <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">Comuni - Rest API</h1>
            <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">Free API per ottenere le informazioni pubbliche dei 7890 comuni italiani</p>
            <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
                <Button color="primary" size='lg' variant="shadow" onClick={() => navigate('/login')}>Login</Button>  
                <Button color="primary" size='lg' variant="shadow" onClick={() => navigate('/register')}>Register</Button>  
            </div>
            <div className="px-4 mx-auto text-center md:max-w-screen-md lg:max-w-screen-lg lg:px-36">
                <span className="font-semibold text-gray-400 uppercase">Fonte Dati</span>
                <div className="flex flex-wrap justify-center items-center mt-8 text-gray-500 sm:justify-between">
                    <a href="https://www.istat.it/it/archivio/6789" className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                        <Chip color="primary" variant="shadow" size='lg'>Istat</Chip>                   
                    </a>
                    <a href="https://dait.interno.gov.it/elezioni/open-data?f%5B0%5D=node%253Afield_argomento%3A180" className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                      <Chip color="primary" variant="shadow" size='lg'>dati.interno.gov.it</Chip>  
                       
                    </a>
                    <a href="https://www.openstreetmap.org" className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                        <Chip color="primary" variant="shadow" size='lg'>OpenStreetMap</Chip>                                        
                    </a> 
                    <a href="https://overpass-turbo.eu" className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400">
                        <Chip color="primary" variant="shadow" size='lg'>Overpass api</Chip>                                        
                    </a>         
                </div>
            </div> 
        </div>
    </section>
    );
  };

export const Stepper = () => {
  return (
    <section className='mt-4 mb-4 container mx-auto'>
        <h3 className="mb-4 text-3xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white">Getting Started Steps</h3>
        <div className='flex justify-center items-center '>
          <ol className="items-center space-y-4 sm:flex sm:space-x-8 sm:space-y-0">
              <li className="flex items-center text-blue-600 dark:text-blue-500 space-x-2.5 rtl:space-x-reverse">
                  <span className="flex items-center justify-center w-8 h-8 border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
                      1
                  </span>
                  <span>
                      <h3 className="font-medium leading-tight">Registrati</h3>
                      <p className="text-sm"></p>
                  </span>
              </li>
              <li className="flex items-center text-blue-600 dark:text-blue-500 space-x-2.5 rtl:space-x-reverse">
                  <span className="flex items-center justify-center w-8 h-8 border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
                      2
                  </span>
                  <span>
                      <h3 className="font-medium leading-tight">Clicca su #Api</h3>
                      <p className="text-sm"></p>
                  </span>
              </li>
              <li className="flex items-center text-blue-600 dark:text-blue-500 space-x-2.5 rtl:space-x-reverse">
                  <span className="flex items-center justify-center w-8 h-8 border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
                      3
                  </span>
                  <span>
                      <h3 className="font-medium leading-tight">Crea il Tuo Token</h3>
                      <p className="text-sm"></p>
                  </span>
              </li>
              <li className="flex items-center text-blue-600 dark:text-blue-500 space-x-2.5 rtl:space-x-reverse">
                  <span className="flex items-center justify-center w-8 h-8 border border-blue-600 rounded-full shrink-0 dark:border-blue-500">
                      4
                  </span>
                  <span>
                      <h3 className="font-medium leading-tight">Fatto!</h3>
                      <p className="text-sm"></p>
                  </span>
              </li>
          </ol>
        </div>

    </section>
  )
}


  export const ExsampleData = () => {
    return (<section className='mb-4'> 
            <h3 className="mb-4 text-3xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white">Esempio Dati</h3>
            <p className='mb-4' >Richiesta</p>
            <div className='mx-auto bg-default-300 rounded-lg max-w-md'>
              <p>
              curl --location  'https://comuni.alessiopellizzaro.it/api/v2/comuni?$filter=Denominazione eq Verona' \
              </p>
              <p>
              --header 'Authorization: Bearer ####YOUR_TOKEN#### '
              </p>
            </div>
            
            <br/>    

            <p className='mb-4 mt-4 '>Risposta</p>
            <div className='mx-auto bg-default-300 rounded-lg max-w-md'>
              <p>"CodiceRegione": 5,</p>
              <p>"ProgressivoDelComune": 91,</p>
              <p>"CodiceComuneFormatoAlfanumerico": "023091", // Codice ISTAT</p>
              <p>"Denominazione": "Verona",</p>
              <p>"DenominazioneIt": "Verona",</p>
              <p>"DenominazioneOther": "",</p>
              <p>"CodiceRipartizioneGeografica": "2",</p>
              <p>"RipartizioneGeografica": "Nord-est",</p>
              <p>"Regione": "Veneto",</p>
              <p>"SiglaAutomobilistica": "VR",</p>
              <p>"CodiceComuneformatoNumerico": 23091,</p>
              <p>"CodiceComuneNumerico_2010_2016": 23091,</p>
              <p>"CodiceComuneNumerico_2006_2009": 23091,</p>
              <p>"CodiceComuneNumerico_1995_2005": 23091,</p>
              <p>"CodiceCatastaleDelComune": "L781",</p>
              <p>"CodiceElettorale": "1050890900",</p>
              <p>"CodiceBelfiore": "L781",</p>
              <p>"PrefissoTelefonico": "045",</p>
              <p>"CodiceCAP": "37121",</p>
              <p>"NUTS1_2010": "ITH",</p>
              <p>"NUTS2_2010": "ITH3",</p>
              <p>"NUTS3_2010": "ITH31",</p>
              <p>"NUTS1_2021": "ITH",</p>
              <p>"NUTS2_2021": "ITH3",</p>
              <p>"NUTS3_2021": "ITH31",</p>
          </div>
            <br/>
            <p>oltre ai campi sopra riportati sono disponibili l'eleco delle strade del teritorio comunale</p>   
            <p> Per maggior informazioni vai alla Documentazione </p>
            <Button color="primary" size='lg' variant="shadow" onClick={() => window.open(`https://comuni.alessiopellizzaro.it/docs/`, "Comuni Docs", 'location=yes,height=570,width=520,scrollbars=yes,status=yes"')}>Comuni Docs</Button>  
            
            </section> );
  }





  export const Footer = () => {
  return (
    <div className="container px-4 pt-5 mx-auto sm:max-w-xl  md:max-w-full  md:px-24 lg:px-8 text-left mt-16 border-t">
      <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
        <div className="sm:col-span-2">
          <a
            href="/"
            aria-label="Go home"
            title="Company"
            className=""
          >
            <span className="text-xl font-bold tracking-wide uppercase">
            Comuni - Rest API
            </span>
          </a>
          <div className=" lg:max-w-sm">
            <p className="text-sm ">
              
            </p>
            <p className="mt-1 mb-4 text-sm ">
            Free API per ottenere le informazioni pubbliche dei 7890 comuni italiani 
            
            </p>

            <a href="https://github.com/APXc" className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700" role="alert">
              <Chip className="px-4 py-1.5 mr-3" color="primary" variant="shadow" size='lg'>Created by</Chip>  
                {/* <span className="text-xs bg-primary-600 rounded-full text-white px-4 py-1.5 mr-3">Created by</span>  */}
                <span className=" text-sm  font-medium">Alessio Pellizzaro</span> 
            </a>
          </div>
        </div>
        <div className="space-y-2 text-sm">
          <p className="text-base font-bold tracking-wide text-primary">
            Contacts
          </p>
          <div className="flex">
            <p className="mr-1">Email:</p>
            <a
              href="mailto:info@alessiopellizzaro.it"
              aria-label="Our email"
              title="Our email"
              className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              info@alessiopellizzaro.it
            </a>
          </div>
          <div className="flex">
            <p className="mr-1">Address:</p>
            <a
              href="https://www.google.com/maps"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Our address"
              title="Our address"
              className="transition-colors duration-300 hover:text-deep-purple-800"
            >
              Via Vittorio Veneto 18 <br /> 37036 San Martino Buon Albergo (VR)  <br /> Italy, Europe
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
        <p className="text-sm text-gray-600">
          2024 © Pellizzaro Development Project By Alessio Pellizzaro - IT04380170243 - Via Vittorio Veneto 18 37036 San Martino Buon Albergo (VR) - Italy - Europe
        </p>
        <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
          <li>
            <a
              href="https://www.iubenda.com/privacy-policy/26387695/legal"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="https://www.iubenda.com/privacy-policy/26387695/cookie-policy"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              Cookie Policy
            </a>
          </li>
          <li>
            <a
              href="/"
              className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              Terms &amp; Conditions
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};